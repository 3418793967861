@import "/node_modules/react-grid-layout/css/styles.css"
@import "/node_modules/react-resizable/css/styles.css"

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important
}

/* needed on chrome */
.cellExpand {
  float: right;
  float: inline-center;
  display: table;
  block-size: 100%; 
  margin-left:10px
}

.cellExpand>span {
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}

.p24 {
  padding: 2px;
  background-color: hsl(0deg 0% 96%);
}

a {
  color: #171A1C;
}

.bgGreen {
  background-color: #E3FBE3;
}

.bgRed {
  background-color: #FBE3E3;
}

.bgNeutral {
  background-color: #F0F0F0;
}

.appRed {
  background-color: #C41C1C !important;
  color: #fff;
}

.appGreen {
  background-color: #E3FBE3 !important;
  color: #000;
}